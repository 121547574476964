/**
 * This template is used to render alternative category index page
 *
 * @flow
 */
import React from 'react';
import { graphql } from 'gatsby';

import type { Props } from '../components/AlternativeArchive';
import AlternativeArchive from '../components/AlternativeArchive';

const AlternativeCategoryTemplate = ({ pageContext, data }: Props) => (
  <AlternativeArchive
    pageContext={pageContext}
    data={data}
  />
);

export default AlternativeCategoryTemplate;

export const pageQuery = graphql`
  query alternativeCategoryPageQuery($skip: Int!, $limit: Int!, $category: String!) {
    alternatives: allWordpressWpAlternativesAndGuides(
      sort: {fields: date, order: DESC}
      skip: $skip
      limit: $limit
      filter: {alternative_categories: {elemMatch: {id: {eq: $category}}}}
    ) {
      ...AlternativesAndGuides
    }
    allTags: allWordpressWpAlternativeTags(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
      edges {
        node {
          id
          path
          name
        }
      }
    }
    allCategories: allWordpressWpAlternativeCategories(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
      edges {
        node {
          id
          path
          name
        }
      }
    }
  }
`;
